.home-bg{
    min-width: 100vw;
    min-height: 100vh;
    background-color: #141619;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hb-main{
    width: 1200px;
    height: 700px;
    background-image: url("../../static/home_bg.png");
    background-size: 100% 100%;
}

.hb-button{
    width: 304px;
    height: 70px;
    background: #F7931A;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin-top: 51px;
    border-radius: 12px;
}
