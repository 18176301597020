.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-row-reserve {
    display: flex;
    flex-direction: row-reverse;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.fl-ai-center {
    align-items: center;
}

.fl-jc-center {
    justify-content: center;
}

.fl-ai-end {
    align-items: flex-end;
}

.fl-jc-between {
    justify-content: space-between;
}

.overflow-hidden {
    overflow: hidden;
}

.flex-shrink0 {
    flex-shrink: 0;
}

.flex-1 {
    flex: 1;
}

.ellipse-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.ellipse-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.common-width{
    width: 1200px;
    margin: 0 auto;
}

.font-color-42{
    color: #424242;
}

.font-color-white{
    color: #ffffff;
}
.font-color-green{
    color: #26ff96;
}
.font-color-red{
    color: #ff3150;
}

.font-color-khaki{
    color: #ece8e1;
}

.bg-color-black{
    background-color: #0f1923;
}

.bg-color-white{
    background-color: #ffffff;
}
.bg-color-green{
    background-color: #26ff96;
}
.bg-color-red{
    background-color: #ff3150;
}

.bg-color-khaki{
    background-color: #ece8e1;
}

.bg-color-white{
    background-color: #ffffff;
}
.font-size-16{
    font-size: 16px;
}

.tac{
    text-align: center;
}
.mr20{
    margin-right: 20px;
}
.ml20{
    margin-left: 20px;
}
